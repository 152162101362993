// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-templates-blog-posts-js": () => import("./../../../src/components/page-templates/blog-posts.js" /* webpackChunkName: "component---src-components-page-templates-blog-posts-js" */),
  "component---src-components-page-templates-blog-tags-js": () => import("./../../../src/components/page-templates/blog-tags.js" /* webpackChunkName: "component---src-components-page-templates-blog-tags-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

